import React, { useState, useEffect } from 'react';
import '../CSS/vocabulary-menu.css'
import { Link } from 'react-router-dom';

const VocabularyMenu = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 990);

   const toggleMenuVisibility = () => {
    setMenuVisible(!menuVisible);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 990);
      if (window.innerWidth > 990) {
        setMenuVisible(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="vocabulary-menu-container">
      <div className="menu-title-container">
        <button 
          className="menu-toggle-button-vocabulary" 
          onClick={toggleMenuVisibility} 
          disabled={!isMobile}
        >
          ☰ More Vocabulary Exercises
        </button>
        <span className="menu-title">More Vocabulary Exercises</span>
      </div>
      <nav className={`vocabulary-navbar ${menuVisible || !isMobile ? 'visible' : ''}`}>
        <ul className="vocabulary-navbar-list">
          <li className="vocabulary-navbar-item">
            <ul className="vocabulary-submenu">
            <li><Link to="/vocabulary-exercises/collocations1"><span className="label label-b2">B2</span> Collocations 1</Link></li>
            <li><Link to="/vocabulary-exercises/word-forms"><span className="label label-c1">B2</span> Word Forms</Link></li> 
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default VocabularyMenu;
