import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useLocation } from 'react-router-dom';
import SEO from '../../Components/SEO';
import Breadcrumbs from '../../Components/Breadcrumb';
import GrammarMenu from '../../Components/GrammarMenu';
import Sidebar from '../../Components/Sidebar';
import YouTubeVideos from '../../Components/YouTubeVideos';
import videoData from './Data/video.json';

const Videos = () => {
  const location = useLocation();
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const topic = params.get('topic');
    if (topic) {
      const videoIndex = videoData.findIndex(video => video.topic === topic);
      if (videoIndex !== -1) {
        setCurrentVideoIndex(videoIndex);
        setAnswers(Array(videoData[videoIndex].questions.length).fill(''));
      }
    }
  }, [location]);

  const handlePreviousVideo = () => {
    const prevVideoIndex = (currentVideoIndex - 1 + videoData.length) % videoData.length;
    setCurrentVideoIndex(prevVideoIndex);
    setAnswers(Array(videoData[prevVideoIndex].questions.length).fill(''));
    setShowResults(false);
  };

  const handleNextVideo = () => {
    const nextVideoIndex = (currentVideoIndex + 1) % videoData.length;
    setCurrentVideoIndex(nextVideoIndex);
    setAnswers(Array(videoData[nextVideoIndex].questions.length).fill(''));
    setShowResults(false);
  };

  const handleAnswerChange = (questionIndex, choice) => {
    const updatedAnswers = [...answers];
    updatedAnswers[questionIndex] = choice;
    setAnswers(updatedAnswers);
    setShowResults(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowResults(true);
  };

  const isCorrect = (questionIndex) => {
    return answers[questionIndex] === videoData[currentVideoIndex].questions[questionIndex].correctAnswer;
  };

  const getResultIcon = (questionIndex, choiceIndex) => {
    if (showResults && isCorrect(questionIndex) && answers[questionIndex] === videoData[currentVideoIndex].questions[questionIndex].choices[choiceIndex]) {
      return <span style={{ color: 'green' }}>&#10003;</span>;
    } else if (showResults && !isCorrect(questionIndex) && answers[questionIndex] === videoData[currentVideoIndex].questions[questionIndex].choices[choiceIndex]) {
      return <span style={{ color: 'red' }}>&#10005;</span>;
    } else {
      return null;
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div>
      <SEO
        title='Video Comprehension Exercises - English Exam Exercises'
        description='A collection of videos to practice listening comprehension'
        name='English Exam Exercises'
        type='article'
      />
      <div className="introtext">
        <Breadcrumbs />
        <h3 className="mt-2">Listening Exercises</h3>
        <p>Here is a collection of exercises to help you master the grammar you need to ace English exams.</p>
      </div>

      <div className="skills-controls">
        <button className="prevnex-button" onClick={toggleMenu}>☰ Video List</button>
        <button
          type="button"
          className="prevnex-button"
          onClick={handlePreviousVideo}
          disabled={currentVideoIndex === 0}
        >
          <i className="fas fa-arrow-left"></i>
        </button>
        <button
          type="button"
          className="prevnex-button"
          onClick={handleNextVideo}
        >
          <i className="fas fa-arrow-right"></i>
        </button>
      </div>

      <div className="grammar-container">
        <div className="exercises-container div1">
          <div>
            <h1>{videoData[currentVideoIndex].title}</h1>
            <div>Topic: {videoData[currentVideoIndex].topic}</div>
            <div>Level: {videoData[currentVideoIndex].level}</div>
            <YouTubeVideos videoId={videoData[currentVideoIndex].videoId} />
          </div>
          <form onSubmit={handleSubmit}>
            <div className="question-container">
              <Carousel interval={null} wrap={false} controls={true} indicators={false} pause={'hover'}>
                {videoData[currentVideoIndex].questions.map((questionObj, questionIndex) => (
                  <Carousel.Item key={questionIndex}>
                    <div className="question-container">
                      <div className="question">{questionObj.number}. {questionObj.question}</div>
                      <div className="choices-container">
                        {questionObj.choices.map((choice, choiceIndex) => (
                          <label key={choiceIndex}>
                            <input
                              type="radio"
                              value={choice}
                              checked={answers[questionIndex] === choice}
                              onChange={() => handleAnswerChange(questionIndex, choice)}
                            />
                            {choice}
                            {getResultIcon(questionIndex, choiceIndex)}
                          </label>
                        ))}
                      </div>
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
              <div>
                {/* Additional content can be added here */}
              </div>
            </div>
            <button type="submit" className="check-answers-button">Check Answers</button>
            <button type="button" className="check-answers-button" onClick={handleNextVideo}>Next Video</button>
          </form>
        </div>

        <div className={`mobile-menu-speaking ${isMenuOpen ? 'open' : ''}`}>
          <div className="set-buttons">
            <button className="close-button" onClick={toggleMenu}>✕</button>
            {videoData.map((video, index) => (
              <button
                key={index}
                className="flashbtn"
                onClick={() => setCurrentVideoIndex(index)}
              >
                {video.title}
              </button>
            ))}
          </div>
        </div>

        <div className="gmenu div2">
          <GrammarMenu />
        </div>
        <div className="div3">
          <Sidebar />
        </div>
      </div>
    </div>
  );
}

export default Videos;
