import React from 'react';

const YouTubeVideo = ({ videoId }) => {
  return (
    <div className="youtube-video">
      <iframe
        src={`https://www.youtube.com/embed/${videoId}`}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{
          width: '100%',
          maxWidth: '560px', // Max width for larger screens
          height: '315px', // Fixed height for aspect ratio
          margin: 'auto', 
          display: 'block', // Prevents bottom margin from affecting alignment
          paddingBottom: '10px'
        }}
      ></iframe>
    </div>
  );
};

export default YouTubeVideo;
