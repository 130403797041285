import React from 'react';
import { Link } from 'react-router-dom';
import SEO from '../Components/SEO';
import Breadcrumb from '../Components/Breadcrumb';

const Topics = () => {
    return (
    <div>
         <SEO
            title='Grammar Exercises - English Exam Exercises'
            description='A big list of English grammar exercises to practice for exams'
            name='English Exam Exercises'
            type='article' />
            <Breadcrumb />
            <div className="introtext">
        <h3 className="mt-2">Topics</h3>
      <p>Here is a collection of exercises organized by topic to help you synthesize the language.</p>
      </div>
            <div className='grammar-container'>
            <div className="exercises-container div1">
        <ul className="all-exercises-list">
        <li><p className="category-title" href="/reading-exercises">Money and Finances</p>
                <ul>
                    <li><Link to="/topics/money">Money</Link></li>
                </ul>
            </li>
            <li><p className="category-title" href="/grammar-exercises">Environment and Nature</p>
                <ul>
                    <li><Link to="/flashcards/b2-adj-flashcards?topic=Health">Health Flashcards</Link></li>
                    <li><Link to="/reading-exercises/reading-comprehension?topic=daily">Health Reading</Link></li>
                    <li><Link to="/speaking-exercises/speaking-topics">Health Speaking Topics</Link></li>
                    <li><Link to="/listening-exercises/videos">Videos</Link></li>
                    <li><Link to="/grammar-exercises/present-perfect">Present Perfect</Link></li>
                    <li><Link to="/grammar-exercises/perfect-past">Present Perfect vs Past Simple</Link></li>
                    <li><Link to="/grammar-exercises/first-conditional">1st Conditional</Link></li>
                    <li><Link to="/grammar-exercises/second-conditional">2nd Conditional</Link></li>
                    <li><Link to="/grammar-exercises/third-conditional">3rd Conditional</Link></li>
                </ul>
            </li>
            <li><p className="category-title" href="/reading-exercises">Education and Work</p>
                <ul>
                    <li><Link to="/reading-exercises/reading-comprehension">Reading Comprehension</Link></li>
                    <li><Link to="/reading-exercises/headings">Paragraph Headings</Link></li>
                </ul>
            </li>
            <li><p className="category-title" href="/speaking-exercises">Health & Fitness</p>
                <ul>
                    <li><Link to="/speaking-exercises/picture-comparison">Picture Comparison</Link></li>
                    <li><Link to="/speaking-exercises/speaking-topics">Speaking Topics</Link></li>
                </ul>
            </li>
            <li><p className="category-title" href="/writing-exercises">Shopping and Consumerism, Money</p>
                <ul>
                    <li><Link to="/writing-exercises/opinions-writing">Opinions</Link></li>
                    <li><Link to="/writing-exercises/pros-cons">Pros/Cons</Link></li>
                    <li><Link to="/writing-exercises/email-writing">Email</Link></li>
                    <li><Link to="/writing-exercises/5-paragraph-essay">5 Paragraph Essay</Link></li>
                </ul>
            </li>
            <li><p className="category-title" href="/listening-exercises">Daily Life and Activities</p>
                <ul>
                    <li><Link to="/listening-exercises/videos">Videos</Link></li>
                    <li><Link to="/listening-exercises/audio">Audio</Link></li>
                </ul>
            </li>
            <li><p className="category-title" href="/vocabulary-exercises">Travel and Holidays</p>
                <ul>
                    <li><Link to="/vocabulary-exercises/flashcardapp">Must-know Words Study Flashcards</Link></li>
                    <li><Link to="/vocabulary-exercises/flashapp">Vocabulary Quizzes</Link></li>
                    <li><Link to="/vocabulary-exercises/collocations1">Collocations</Link></li>
                    <li><Link to="/vocabulary-exercises/collocations2">Synonyms and Antonyms</Link></li>
                </ul>
            </li>

            <li><p className="category-title" href="/listening-exercises">Technology</p>
                <ul>
                    <li><Link to="/listening-exercises/videos">Videos</Link></li>
                    <li><Link to="/listening-exercises/audio">Audio</Link></li>
                </ul>
            </li>

            <li><p className="category-title" href="/listening-exercises">Social Issues</p>
                <ul>
                    <li><Link to="/listening-exercises/videos">Videos</Link></li>
                    <li><Link to="/listening-exercises/audio">Audio</Link></li>
                </ul>
            </li>

            <li><p className="category-title" href="/listening-exercises">Media and Entertainment</p>
                <ul>
                    <li><Link to="/listening-exercises/videos">Videos</Link></li>
                    <li><Link to="/listening-exercises/audio">Audio</Link></li>
                </ul>
            </li>

            <li><p className="category-title" href="/listening-exercises">Food and Cooking</p>
                <ul>
                    <li><Link to="/listening-exercises/videos">Videos</Link></li>
                    <li><Link to="/listening-exercises/audio">Audio</Link></li>
                </ul>
            </li>




        </ul>
        </div>







            <figure data-title="Topics">
                                        <figcaption>
                                                <p>Choose your topic to find assorted related exercises. </p>
                                        <ul>
                                        <li><Link to="/#"><span class="label label-a1">A1</span> Environment</Link></li>
                                        <li><Link to="/#"><span class="label label-a2">A2</span> Education</Link></li>
                                                <li><Link to="/#"><span class="label label-b1">B1</span> Travel and Holidays</Link></li>
                                                <li><Link to="/#"><span class="label label-b2">B2</span> Money</Link></li>
                                                <li><Link to="/#"><span class="label label-c1">C1</span> Family and Friends</Link></li>
                                        </ul>
                                        </figcaption>
                                </figure>
          
                    
                    <div className='lead-container'>
                    <div className="homebodytext">
                                <h2><strong>Topics</strong></h2>
                                <p>Practice exercises oraganized around typical topics found on exams. </p>
                            </div>
                        
                        </div>
                
                </div>
    </div>
    )
}
 
export default Topics;

