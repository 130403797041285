											 
import React, { useState, useEffect } from 'react';
								  
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Breadcrumbs from '../../Components/Breadcrumb';
import Sidebar from '../../Components/Sidebar';
import GrammarMenu from '../../Components/GrammarMenu';
import SEO from '../../Components/SEO';

// Import the JSON data for Third Conditionals
const presentPerfectData = require('./Data/presentperfect.json');

const PresentPerfect = () => {
  // State for storing user answers, current index, and tab data
  const [answers, setAnswers] = useState([]);
  const [evaluation, setEvaluation] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

						  
																   
  const [positiveData, setPositiveData] = useState([]);
  const [negativeData, setNegativeData] = useState([]);
  const [questionData, setQuestionData] = useState([]);

  // Function to generate new sentences for the current tab based on currentIndex
  const generateNewSentences = (tabData) => {
    const startIndex = currentIndex;
    const endIndex = Math.min(startIndex + 10, tabData.length);
    return tabData.slice(startIndex, endIndex).map(sentenceObj => sentenceObj.text);
  };

  // Function to handle input change for each sentence
  const handleChange = (index, value) => {
    setAnswers(prevAnswers => {
      const updatedAnswers = [...prevAnswers];
      updatedAnswers[currentIndex + index] = value; // Adjust index by currentIndex
      return updatedAnswers;
    });
  };

  

 // Function to handle moving to previous exercise
const handlePreviousExercise = (tabData) => {
    const previousIndex = (currentIndex - 10 + tabData.length) % tabData.length;
    setCurrentIndex(previousIndex);
    setAnswers(Array(10).fill(null)); // Clear answers
    setEvaluation([]); // Reset evaluation
  };
  
  // Function to handle moving to next exercise
  const handleNextExercise = (tabData) => {
    const nextIndex = Math.min(currentIndex + 10, tabData.length);
    setCurrentIndex(nextIndex >= tabData.length ? 0 : nextIndex);
    setAnswers(Array(10).fill(null)); // Clear answers
    setEvaluation([]); // Reset evaluation
  };
  
  // Function to handle resetting the current tab
  const handleResetTab = () => {
    setCurrentIndex(0);
    setAnswers(Array(10).fill(null)); // Clear answers
    setEvaluation([]); // Reset evaluation
  };
  
// Function to handle form submission and evaluate answers
const handleSubmit = (e, tabData) => {
    e.preventDefault();
    const slicedTabData = tabData.slice(currentIndex, currentIndex + 10);
    const updatedEvaluation = slicedTabData.map((sentenceObj, index) => {
      const inputValue = answers[currentIndex + index]; // Adjust index by currentIndex
      const correctAnswer = sentenceObj.answer;
      // Check if inputValue is not empty or null
      if (inputValue && inputValue.trim() !== '') {
        return inputValue.trim().toLowerCase() === correctAnswer.trim().toLowerCase() ? 'correct' : 'incorrect';
      } else {
        // If no answer provided, return 'incorrect'
        return 'incorrect';
      }		   
																				  
    });
				
    setEvaluation(updatedEvaluation);
  };
  
  
  useEffect(() => {
    // Check if data is already loaded
    if (positiveData.length === 0 && negativeData.length === 0 && questionData.length === 0) {
      // Load data only if it hasn't been loaded before
      setPositiveData(presentPerfectData.positive);
      setNegativeData(presentPerfectData.negative);
      setQuestionData(presentPerfectData.question);
    }
  }, [positiveData, negativeData, questionData]);
  
  // Update answers when currentIndex changes
  useEffect(() => {
    setAnswers(Array(10).fill(null)); // Clear answers
  }, [currentIndex]);
  
					
	  
  
  return (
    <div>
      <SEO
            title='Grammar Exercises - Present Perfect'
            description='A big list of English grammar exercises to practice for exams'
            name='English Exam Exercises'
            type='article' />
            <Breadcrumbs />
 
 <div className='home-container'>
       
   <div className='homebodytext div1'>
           <div className="introtext">
       <h3 className="mt-2">Present Perfect</h3>
     </div>				
    <div className="page-description">Here is a collection of grammar exercises to do. The exercises are divided by categories which correspond to exam levels. Each category here has 10 exercises, but there are more once you go to the page. Keep chcking back as I am always adding new exercises.</div>	
      
      <div className="exercises-container ">
         
      <div className="buttonctrl">
                                    <button type="button" onClick={() => handlePreviousExercise(positiveData)}><i className="fas fa-arrow-left"></i></button>
                                    <button type="button" onClick={() => handleNextExercise(positiveData)}><i className="fas fa-arrow-right"></i></button>
                                    </div>	
           <div className="page-description">Complete the sentences with the appropriate words.</div>
        <Tabs>
          <TabList>
            <Tab className="custom-tab positive-tab" onClick={handleResetTab}>Positive</Tab>
            <Tab className="custom-tab negative-tab" onClick={handleResetTab}>Negative</Tab>
            <Tab className="custom-tab question-tab" onClick={handleResetTab}>Question</Tab>
          </TabList>

          {/* Positive Tab Panel */}
          <TabPanel>
              <Tabs>
                <TabList>
                <Tab>Exercise</Tab>
                <Tab>Explanation</Tab>
                </TabList>
              
              <TabPanel>
		        		  
            <form className="exercises-form" onSubmit={(e) => handleSubmit(e, positiveData)}>
              {generateNewSentences(positiveData).map((sentence, index) => (
                <div key={index}>
                  <div className="sentence-container">
                    <span className="circle">{index + 1}</span>
                    {sentence.split(' ___ ').map((part, partIndex) => (
                      <React.Fragment key={partIndex}>
                        {partIndex > 0 && (
                          <input
                            type="text"
                            value={answers[currentIndex + index] || ''}
                            onChange={(e) => handleChange(index, e.target.value)}
                          />
                        )}
                        {part}
                      </React.Fragment>
                    ))}
                    <button className='checkbtn' type="submit">Check</button>
                    {evaluation[index] === 'correct' ? ' ✔️' : evaluation[index] === 'incorrect' ? ' ❌' : ''}
                  </div>
                </div>
              ))}
			   
              
			  
			  
			  
			  <button type="submit">Check</button>
              <button type="button" onClick={() => handlePreviousExercise(positiveData)}>Previous Exercise</button>
              <button type="button" onClick={() => handleNextExercise(positiveData)}>Next Exercise</button>
              <button type="button" onClick={handleResetTab}>Reset</button>
            </form>
          </TabPanel>
	
	  
	  <TabPanel>
    <div>
                                              <h5><strong>Present Perfect have/has + 3rd form</strong></h5>
                                                <p>We use the present perfect <em>have/has + 3rd form</em> to talk about past actions that are related to the present. We use words such as yet, already, never, ever with this tense. </p> 
                                                <p>The present perfect is frequently used with for and since to talk about an action that started in the past but is still going on.  </p>
                                                <p>The present is also used to say how many times something has happened. </p>
                                                <p><strong>Examples:</strong></p>
				
			  
                                                <ul>
                                                  <li>I have already finished the homework.</li>
                                                  <li>I've lived in Budapest for 33 years.</li>	
                                                  <li>She's been to France 6 times.</li>			 
                                                </ul>

                                              </div>
          </TabPanel>
		   </Tabs>
      </TabPanel>
         
		 {/* Negative Tab Panel */}
          <TabPanel>
          <Tabs>
			  <TabList>
				<Tab>Exercise</Tab>
				<Tab>Explanation</Tab>
			  </TabList>
			<TabPanel>
            <form className="exercises-form" onSubmit={(e) => handleSubmit(e, negativeData)}>
              {generateNewSentences(negativeData).map((sentence, index) => (
                <div key={index}>
                  <div className="sentence-container">
                    <span className="circle">{index + 1}</span>
                    {sentence.split('___').map((part, partIndex) => (
                      <React.Fragment key={partIndex}>
                        {partIndex > 0 && (
                          <input
                            type="text"
                            value={answers[currentIndex + index] || ''}
                            onChange={(e) => handleChange(index, e.target.value)}
                          />
                        )}
						 
                        {part}
                      </React.Fragment>
                    ))}
                    <button className='checkbtn' type="submit">Check</button>	
                    {evaluation[index] === 'correct' ? ' ✔️' : evaluation[index] === 'incorrect' ? ' ❌' : ''}
                  </div>
                </div>
					
              ))}
              <button type="submit">Check</button>
              <button type="button" onClick={() => handlePreviousExercise(negativeData)}>Previous Exercise</button>
              <button type="button" onClick={() => handleNextExercise(negativeData)}>Next Exercise</button>
              <button type="button" onClick={handleResetTab}>Reset</button>
            </form>
          </TabPanel>
	  
		<TabPanel>
    <div>
                                              <h5><strong>Present Perfect have/has + 3rd form</strong></h5>
                                                <p>We use the present perfect <em>have/has + 3rd form</em> to talk about past actions that are related to the present. We use words such as yet, already, never, ever with this tense. </p> 
                                                <p>The present perfect is frequently used with for and since to talk about an action that started in the past but is still going on.  </p>
                                                <p>The present is also used to say how many times something has happened. </p>
                                                <p><strong>Examples:</strong></p>
				
			  
                                                <ul>
                                                  <li>I haven't finished the homework yet.</li>
                                                  <li>I haven't had this car for a long time.</li>	
                                                  <li>She hasn't been to France in a while.</li>			 
                                                </ul>

                                              </div>
          </TabPanel>
		   </Tabs>
      </TabPanel>

          {/* Question Tab Panel */}
          <TabPanel>
          <Tabs>
			  <TabList>
				<Tab>Exercise</Tab>
				<Tab>Explanation</Tab>
			  </TabList>
					
          <TabPanel>
            <form className="exercises-form" onSubmit={(e) => handleSubmit(e, questionData)}>
              {generateNewSentences(questionData).map((sentence, index) => (
					   
				
                <div key={index}>
                  <div className="sentence-container">
                    <span className="circle">{index + 1}</span>
                    {sentence.split('___').map((part, partIndex) => (
                      <React.Fragment key={partIndex}>
                        {partIndex > 0 && (
                          <input
                            type="text"
                            value={answers[currentIndex + index] || ''}
                            onChange={(e) => handleChange(index, e.target.value)}
                          />
                        )}
							 
                        {part}
                      </React.Fragment>
                    ))}
                    <button className='checkbtn' type="submit">Check</button>	
                    {evaluation[index] === 'correct' ? ' ✔️' : evaluation[index] === 'incorrect' ? ' ❌' : ''}
						  
                  </div>
                </div>
              ))}
			   
              <button type="submit">Check</button>
              <button type="button" onClick={() => handlePreviousExercise(questionData)}>Previous Exercise</button>
              <button type="button" onClick={() => handleNextExercise(questionData)}>Next Exercise</button>
              <button type="button" onClick={handleResetTab}>Reset</button>
            </form>
          </TabPanel>
 <TabPanel>
 <div>
                                              <h5><strong>Present Perfect have/has + 3rd form</strong></h5>
                                                <p>We use the present perfect <em>have/has + 3rd form</em> to talk about past actions that are related to the present. We use words such as yet, already, never, ever with this tense. </p> 
                                                <p>The present perfect is frequently used with for and since to talk about an action that started in the past but is still going on.  </p>
                                                <p>The present is also used to say how many times something has happened. </p>
                                                <p><strong>Examples:</strong></p>
				
			  
                                                <ul>
                                                  <li>Have you finished the homework yet?</li>
                                                  <li>How long have you had this car?</li>	
                                                  <li>How many times has she been to France?</li>			 
                                                </ul>

                                              </div>
                                              </TabPanel>
        </Tabs>
      </TabPanel>
    </Tabs>
	 
      </div> </div>

      <div className="gmenu div2"> <GrammarMenu /> 
      </div>

      <div className="div3"> 

            <Sidebar />
      </div>
      </div>
   
    </div>
  );
};

export default PresentPerfect;

						  