import React from 'react';
import { Link } from 'react-router-dom';
import SEO from '../../Components/SEO';
import Breadcrumb from '../../Components/Breadcrumb';

const Money = () => {
    return (
    <div>
         <SEO
            title='Exam Topics - Money - English Exam Exercises'
            description='A group of English exam exercises centered around the topic of money'
            name='English Exam Exercises - Money'
            type='article' />
            <Breadcrumb />
            <div className="introtext">
        <h3 className="mt-2">Money</h3>
      <p>Here is a collection of exercises organized by topic to help you synthesize the language.</p>
      </div>
            <div className='grammar-container'>
            <div className="exercises-container div1">
        <ul className="all-exercises-list">
            <li><p className="category-title" href="/grammar-exercises">Money</p>
                <ul>
                    <li><Link to="/flashcards/b2-adj-flashcards?topic=Money">Money Flashcards</Link></li>
                    <li><Link to="/reading-exercises/reading-comprehension?topic=Money">Money Reading</Link></li>
                    <li><Link to="/speaking-exercises/speaking-topics?topic=Money">Money Speaking Topics</Link></li>
                    <li><Link to="/listening-exercises/videos?topic=Money">Money Videos</Link></li>
                </ul>
            </li>
            




        </ul>
        </div>







            <figure data-title="Topics">
                                        <figcaption>
                                                <p>Choose your topic to find assorted related exercises. </p>
                                        <ul>
                                        <li><Link to="/#"><span class="label label-a1">A1</span> Environment</Link></li>
                                        <li><Link to="/#"><span class="label label-a2">A2</span> Education</Link></li>
                                                <li><Link to="/#"><span class="label label-b1">B1</span> Travel and Holidays</Link></li>
                                                <li><Link to="/#"><span class="label label-b2">B2</span> Money</Link></li>
                                                <li><Link to="/#"><span class="label label-c1">C1</span> Family and Friends</Link></li>
                                        </ul>
                                        </figcaption>
                                </figure>
          
                    
                    <div className='lead-container'>
                    <div className="homebodytext">
                                <h2><strong>Topics</strong></h2>
                                <p>Practice exercises oraganized around typical topics found on exams. </p>
                            </div>
                        
                        </div>
                
                </div>
    </div>
    )
}
 
export default Money;

